import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModeOrder } from 'src/app/core/models/mode-order.model';

@Injectable({ providedIn: 'root' })
export class ModeOrderService {
    private currentModeOrderSubject: BehaviorSubject<ModeOrder>;
    public currentMode: Observable<ModeOrder>;

    constructor(public http: HttpClient, public snackBar: MatSnackBar) {

        const storedModeOrder = sessionStorage.getItem('modeOrder')
        const parsedModeOrder = storedModeOrder ? JSON.parse(storedModeOrder) : new ModeOrder()
        this.currentModeOrderSubject = new BehaviorSubject<ModeOrder>(parsedModeOrder);
        this.currentMode = this.currentModeOrderSubject.asObservable();
    }
    public get currentModeOrderValue(): ModeOrder {
        return this.currentModeOrderSubject.value;
    }

    updateCurrentModeOrder(data: ModeOrder) {
        sessionStorage.setItem('modeOrder', JSON.stringify(data));
        this.currentModeOrderSubject.next(data);
    }
} 