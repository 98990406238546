import { PROVIDER } from "src/environments/config"


// ejemplo codigo normal - '000000001-0000000306'
const sizeCode = 15
let prefijoCode = Array((19 - sizeCode) - String(PROVIDER.providerId).length + 1).join('0') + PROVIDER.providerId

export function encodeCodeProduct(code: string) {
  let num = code.split('-')
  return PROVIDER.providerId == null ? code : Number(num[1]) ? Number(num[1]).toString() : num[1].toString()
}

export function decodeCodeProduct(code: string) {

  if (PROVIDER.providerId == null) {
    return code
  }

  let number = Number(code)
  let numberOutput =  Number(code) ? Math.abs(number): code ; /* Valor absoluto del número */
  let length = Number(code) ? number.toString().length: code.toString().length; /* Largo del número */
  let zero = "0"; /* String de cero */

  if (sizeCode <= length) {
    if (number < 0) {
      return ("-" + numberOutput.toString());
    } else {
      return prefijoCode + '-' + numberOutput.toString();
    }
  } else {
    if (number < 0) {
      return ("-" + (zero.repeat(sizeCode - length)) + numberOutput.toString());
    } else {
      return prefijoCode + '-' + ((zero.repeat(sizeCode - length)) + numberOutput.toString());
    }
  }
}

export function eliminarDiacriticos(texto:string) {
  texto = texto.toLowerCase();
  texto = texto.replace(/ +/g,"-");
  texto = texto.replace(/--/g, "-");
  texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  texto = texto.replace(/[^a-zA-Z0-9 !-]/g, "");
  texto = texto.replace(/!/g, "-");
  return texto
}

export function eliminarDiacriticoCategory(texto: string){
  texto = texto.toLowerCase();
  return texto
}

export function eliminarDiacriticoMiga(texto:string) {
  texto = texto.replace(/ +/g, " ");
  texto = texto.replace(/--/g, "");
  texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  texto = texto.replace(/[^a-zA-Z0-9 -]/g, "");
  return texto
}

export function restaurarDiacriticos(texto:string) {
  texto = texto.replace(/-/g, "!");
  texto = texto.replace(/[^a-zA-Z0-9 !]/g, "");
  texto = texto.replace(/!/g, " ");
  texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  texto = texto.replace(/--/g, " ");
  texto = texto.replace(/ +/g," ");
  texto = texto.replace(/\b\w/g, function(l) { return l.toUpperCase() });
  return texto
}