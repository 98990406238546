import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { Banner } from "src/app/core/models/banner.model"
import { Category } from "src/app/core/models/category-model"
import { PROVIDER } from "src/environments/config"
import { environment } from "src/environments/environment"
import { ModeOrderService } from "./mode-order.service"
import { MatSnackBar } from "@angular/material/snack-bar"

export class Data {
    constructor(public categories: Category[],
        public compareList: any[],
        public wishList: any[],
        public cartList: any[],
        public totalPrice: number,
        public totalCartCount: number) { }
}

@Injectable({ providedIn: 'root' })
export class EcommerceService {
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        0, //totalPrice,
        0 //totalCartCount
    )

    domain = environment.domain
    url_s3 = environment.url_amazon_s3

    addChange = new BehaviorSubject<any>(null) // AUXILIAR PARA DETECTAR cuando un producto fue añadido

    constructor(
        public http: HttpClient,
        private modeService: ModeOrderService,
        public snackBar: MatSnackBar,
    ) {
        this.defaultStorageLocalProduct()
    }

    //start storage
    defaultStorageLocalProduct() {
        const cartProduct = localStorage.getItem("cartProduct");
        if (cartProduct === null || cartProduct === undefined) {
            this.setStorageData();
        }
        this.getLocalCart();
    }

    setStorageData() {
        localStorage.setItem("cartProduct", JSON.stringify(this.Data));
    }

    getLocalCart() {
        const cartProduct = localStorage.getItem("cartProduct");
        this.Data = cartProduct ? JSON.parse(cartProduct) : [];
    }
    //end storage

    getBanners(): Observable<Banner[]> {
        return this.http.get<Banner[]>(this.domain + 'api/em/slides/get/horizontal/' + (PROVIDER.providerId == null ? '' : PROVIDER.providerId))
    }

    getCategories(): Observable<Category[]> {
        let url = this.domain + 'api/em/material_group/get_cb/' + (PROVIDER.providerId == null ? '' : PROVIDER.providerId) + (this.modeService.currentModeOrderValue == null ? '' : (!this.modeService.currentModeOrderValue.storeSelected.id ? '' : '/' + this.modeService.currentModeOrderValue.storeSelected.id + '/' + (this.modeService.currentModeOrderValue.orderSelected == 'PD' ? 'PD' : this.modeService.currentModeOrderValue.subOrderSelected)))
        return this.http.get<Category[]>(url);
    }

    getAllProducts(filter:any, skip: any, take:any): Observable<any[]> {
        let url = this.domain + `api/em/material/paginate?skip=${skip}&take=${take}`
        return this.http.post<any[]>(url, filter)
    }

    public getProductById(code: string, type: string): Observable<any> {
        //TO DO Agregar tipo de dato Producto
        return this.http.get<any>(this.domain + `api/em/material/get/${code}/${type}/0`)
    }

    getPathImageSlides(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/banner/' + image
    }

    getPathCategory(image: string){
        let url_amazon = this.url_s3
        return url_amazon + '/category/' + image
    }

    // IMAGENES PRODUCTOS
    public getPathImageOriginal(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/original/' + image
    }

    public getPathImageSmall(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/150X150/' + image
    }
    
    public getPathImageMedium(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/500X500/' + image
    }
    
    public getPathImageBig(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/1024X1024/' + image
    }

    public getSuggested(data:any) {
        return this.http.get<any[]>(this.domain + 'api/em/material/get_suggested');
    }

    addToCart(product: any) {
        let message, status;

        this.Data.totalPrice = 0;
        this.Data.totalCartCount = 0;

        if (this.Data.cartList.filter(item => item.code == product.code)[0]) {
            let item = this.Data.cartList.filter(item => item.code == product.code)[0];

            if (product.type == 'A') { //aditional
                let items = this.Data.cartList.filter(item => {
                    return (item.code.split('*')[0]) == product.code
                })
                let auxObj = Object.assign({}, product)
                auxObj.code = auxObj.code + '*' + (items.length + 1)
                this.Data.cartList.push(auxObj)
            } else {
                item.cartCount = product.cartCount;
            }
        }
        else {
            this.Data.cartList.push(product);
        }
        this.Data.cartList.forEach(product => {
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
            this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        });

        message = 'Producto agregado a tu Carrito.';
        status = 'success';
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
        this.addChange.next(true)
        this.setStorageData()
    }
}
