// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  criptoKey: '4582456213254840', //Preguntar si es otro en cafe
  url_amazon_s3: 'https://casadelcafefiles.s3.eu-central-1.amazonaws.com/folder',
  
  domain: 'https://coffeehouse-qas.aveapplications.com/',
  //domain: 'https://coffeehouse.aveapplications.com/',

  proyectId: "796281563442",// ID DEL PROYECTO PARA LA NOTIFICACION
  topic: "topic_cc",
  providerNotification: "fcm",
  firebaseConfig: {
    apiKey: "AIzaSyCYJ_lJ-MD7KoJvGb8c5adWNwAqHwf0aqE",
    authDomain: "casa-del-caffe.firebaseapp.com",
    databaseURL: "https://casa-del-caffe.firebaseio.com",
    projectId: "casa-del-caffe",
    storageBucket: "casa-del-caffe.appspot.com",
    messagingSenderId: "796281563442",
    appId: "1:796281563442:web:a8030cd08116f2e5620f2b",
    measurementId: "G-QPR40LFJK6",
    vapidKey: "936xnkUWYeUYDxKOV6huFdqM_pPtbYddEys9fvH3PWk"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
