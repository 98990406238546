import { MatDialog } from '@angular/material/dialog';
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { RequestNotificationComponent } from 'src/app/shared/request-notification/request-notification.component';
import { messaging } from '../../../configs/firebase.config';
import { PROVIDER } from 'src/environments/config';


@Injectable({ providedIn: 'root' })
export class NotificationService {
    private URL = environment.domain
    public currentNtifiySubject: BehaviorSubject<any>;
    public permitiNotificacion: BehaviorSubject<any>;
    public currentNotify: Observable<any>;

    //aux
    token = null

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
    ) {
        const storedNotification = localStorage.getItem('currentNotification');
        const parsedNotification = storedNotification ? JSON.parse(storedNotification) : null;
        this.currentNtifiySubject = new BehaviorSubject<any>(parsedNotification);
        this.permitiNotificacion = new BehaviorSubject<any>(localStorage.getItem('activeNotification'));

        this.currentNotify = this.currentNtifiySubject.asObservable();
    }

    public get currentNotifyValue(): any {
        return this.currentNtifiySubject.value;
    }

    async activeNotification(user?: any) {
        if (this.currentNotifyValue == null) {
            const dialogRef = this.dialog.open(RequestNotificationComponent, {
                data: null,
                position: { top: "20px" },
                width: "400px",
                disableClose: true,
            })

            let result = await dialogRef.afterClosed().toPromise()
            await this.handleResult(result, user);
        }
    }

    async handleResult(result: string, user?: any): Promise<void> {
        if (result === 'no') {
            localStorage.setItem("activeNotification", "0");
            this.permitiNotificacion.next('0');
            return;
        }
        if (result === 'yes') {
            if (Notification.permission !== 'granted') {
                await Notification.requestPermission();
            }
            this.requestPushNotificationsPermission(user);
        }
    }

    async requestPushNotificationsPermission(user?: any) {
        messaging.getToken()
        .then((token) => {
            console.log('my fcm token', token);
            if (this.currentNotifyValue == null) {

                var obj = {
                    "token": token, // TOKEN DEL DISPOSITIVO
                    "customerId": user != null ? user.bp_id : null, // ID DEL USUARIO, PUEDE SER NULO
                    "providerId": PROVIDER.providerId, // id DE LA MARCA, OBIGATIGATORIO
                    "topic": environment.topic, // TOPIC AL CUAL SE SUBCRIBIRA LA NOTIFICACION
                    "provider": environment.providerNotification,// "push_kit"
                    "projectId": environment.proyectId
                };
                this.create(obj).subscribe()
            } else {
                if (this.currentNotifyValue.tokenId != token) {
                    var obj = {
                        "token": token, // TOKEN DEL DISPOSITIVO
                        "customerId": user != null ? user.bp_id : null, // ID DEL USUARIO, PUEDE SER NULO
                        "providerId": PROVIDER.providerId, // id DE LA MARCA, OBIGATIGATORIO
                        "topic": environment.topic, // TOPIC AL CUAL SE SUBCRIBIRA LA NOTIFICACION
                        "provider": environment.providerNotification,// "push_kit"
                        "projectId": environment.proyectId
                    };
                    console.log('ACTUALIZARE', obj);
                    this.update(this.currentNotifyValue.id, { token: token }).subscribe()
                }
            }
        }).catch((err) => {
            console.log(err);
        }); 

        messaging.onMessage((incomingMessage) => {
            console.log("recibii", incomingMessage);
        })
    }

    deleteToken() {
        messaging.deleteToken()
       /* TODO VERIFICAR EL ELIMINAR
       messaging.getToken.pipe()
        .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
        .subscribe(
            (token: any) => { console.log('Token deleted!'); },
        ); */
    }

    create(data: Object) {
        let url_api = this.URL + 'api/device-web/set/'
        return this.http.post<any>(url_api, data)
        .pipe(map(res => {
            localStorage.setItem('currentNotification', JSON.stringify(res));
            this.currentNtifiySubject.next(res);
            localStorage.setItem("activeNotification", "1")
            this.permitiNotificacion.next('1')
            return res;
        }));
    }

    update(id: string, data: Object) {
        let url_api = this.URL + 'api/device-web/update/' + id
        return this.http.put<any>(url_api, data)
        .pipe(map(res => {
            localStorage.setItem('currentNotification', JSON.stringify(res));
            this.currentNtifiySubject.next(res);
            return res;
        }));
    }

    delete(id: any) {
        let url_api = this.URL + 'api/device-web/delete/' + id
        return this.http.delete<any>(url_api, id)
        .pipe(map(res => {
            localStorage.removeItem('currentNotification');
            this.currentNtifiySubject.next(null);
            return res;
        }));
    }
}