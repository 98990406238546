import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {

  public isCollapsed = true;
  
  _user:any = null
  obs: Subscription[] = []

  constructor(
    private authService: AuthenticationService,
  ) {
    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this._user = res == null ? null : res.user
      })
    )
  }

  ngOnInit(): void {
    const pathName = window.location.pathname;
    const items = Array.from(document.querySelectorAll("a.menulist"));
    let matchingMenuItem = items.find((x: any) => {
      return x.pathname === pathName;
    });
    matchingMenuItem?.classList.add('active')
  }

  ngOnDestroy() {
    this.obs.forEach(element => {
      element.unsubscribe()
    });
  }
}
