<div class="bg-white rounded-3 shadow-lg pt-1 mb-5 mb-lg-0">
    <div class="d-md-flex justify-content-between align-items-center text-center text-md-start px-2 py-4">
        @if(this._user){
            <div class="d-md-flex align-items-center">
                <div class="ps-md-3">
                    <h3 class="fs-base mb-0">{{this._user.bp_agent}}</h3>
                    <span class="text-accent fs-sm">{{this._user.email}}</span>
                </div>
            </div>
        }
        <a class="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0" href="javascript:void(0);"
        data-bs-toggle="collapse" aria-expanded="false"  data-bs-target="#navbarCollapse" (click)="collapse.toggle()">
            <i class="ci-menu me-2"></i>{{'TRANSLATE.MENU' | translate}}
        </a>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="d-lg-block collapse" id="account-menu" >
        <div class="bg-secondary px-4 py-3">
            <h3 class="fs-sm mb-0 text-muted">{{'ACCOUNT.ACCOUNT_SETTINGS' | translate}}</h3>
        </div>
        <ul class="list-unstyled mb-0">
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/cuenta/informacion">
                    <i class="ci-user opacity-60 me-2"></i>{{'ACCOUNT.ACCOUNT' | translate}}
                </a>
            </li>
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/cuenta/comentario">
                    <i class="ci-book opacity-60 me-2"></i>{{'ACCOUNT.SEND_FEEDBACK' | translate}}
                </a>
            </li>
            <li class="mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/cuenta/tarjetas">
                    <i class="ci-card opacity-60 me-2"></i>{{'ACCOUNT.PAYMENT_METHODS' | translate}}
                </a>
            </li>
        </ul>
        <div class="bg-secondary px-4 py-3">
            <h3 class="fs-sm mb-0 text-muted">{{'ACCOUNT.DASHBOARD' | translate}}</h3>
        </div>
        <ul class="list-unstyled mb-0">
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/cuenta/pedidos">
                    <i class="ci-bag opacity-60 me-2"></i>{{'ACCOUNT.MY_ORDERS' | translate}}
                </a>
            </li>
        </ul>
    </div>
</div>