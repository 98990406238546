import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-custom',
  templateUrl: './dialog-custom.component.html',
  styleUrls: ['./dialog-custom.component.scss']
})
export class DialogCustomComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogCustomComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  yes() {
    this.dialogRef.close("yes");
  }
  no() {
    this.dialogRef.close("no");
  }
}
