import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrl: './controls.component.scss'
})
export class ControlsComponent {
  @Input() product: any; // TODO: CAMBAIR A ALA CLASE PRODUCT

  @Input() aditional?: any = null; // TODO: solo si el producto es de tipo A
  @Input() aditionalSelected?: any; // TODO: solo si el producto es de tipo A

  constructor(
    public ecommerceService: EcommerceService, 
    public snackBar: MatSnackBar
  ){

  }

  ngOnInit() {
  }

  public async addToCart(product: any) {
    if (product.type == 'A') {
      if (this.aditional != null && (this.onVerifiedQuantitySubnivel(this.aditionalSelected))) {
        this.snackBar.open('Por favor, completa las selecciones requeridas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return
      }
    }

    let det = []
    if (this.aditional != null) {
      det = this.aditionalSelected.selected.slice()
    }

    let additionalDetail = []
    for (const mat of det) {
      if (mat.material.type == null) {
        additionalDetail.push(mat)
      } else {
        for (const mad of mat.aditionalSelected.selected) { // si es de tipo "A"
          additionalDetail.push(mad)
        }
      }
    }
    product.aditionalDetailV2 = additionalDetail

    let auxproduct = Object.assign({}, product)
    if (product.type == 'A') {
      auxproduct.aditionalSelected = this.aditionalSelected.selected.slice()
      auxproduct.newPrice = this.getTotalItemSelected()
    }

    this.ecommerceService.addToCart(auxproduct);

  }

  onVerifiedQuantitySubnivel(aditionalSelected: any) {
    let found = false
    for (let item of aditionalSelected.selected) {
      if (item.material.type == 'A' && item.material.aditional.quantity > 0) {
        let sum = 0
        for (let i of item.aditionalSelected.selected) {
          sum = sum + i.quantity
        }
        if (!(sum >= item.material.aditional.minQuantity && sum <= item.material.aditional.quantity)) {
          found = true
          break
        }
      }
    }
    return found
  }

  getTotalItemSelected() {
    let res = 0
    if (this.product == null) {
      return 0
    }
    res = Number(this.product.newPrice)
    if (this.product.type == 'A') {
      for (let item of this.aditionalSelected.selected) {
        if (item.material.type == 'A') {
          item.aditionalSelected.selected.forEach((element:any) => {

            if (element.isAditionalPrice == '1') {
              res = res + (Number(element.price) * element.quantity)
            }
          });
        }
      }
    }
    return res
  }
}
