import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { News } from "src/app/core/models/news.model"
import { PROVIDER } from "src/environments/config"
import { environment } from "src/environments/environment"

@Injectable({ providedIn: 'root' })
export class LandingService {
domain = environment.domain
url_s3 = environment.url_amazon_s3

constructor(
        public http: HttpClient
    ) {}

    getNews(): Observable<News[]> {
        let url = this.domain + 'api/em/news/get'
        return this.http.get<News[]>(url)
    }

    getSingleNews(id: number): Observable<News>{
        let url = this.domain + `api/em/news/get/${id}`
        return this.http.get<News>(url);
    }

    getPathImageNews(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/news/' + image
    }

}
