import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbNavModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

// scroll package
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';

// component
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { NewsCarouselComponent } from './news-carousel/news-carousel.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LanguageService } from '../services/global/language.service';
import { RequestNotificationComponent } from './request-notification/request-notification.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { DialogCustomComponent } from './dialog-custom/dialog-custom.component';
import { ControlsComponent } from './controls/controls.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NewsCarouselComponent,
    BreadcrumbComponent,
    RequestNotificationComponent,
    SidemenuComponent,
    DialogCustomComponent,
    ControlsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbDropdownModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    ScrollToModule.forRoot(),
    MatIconModule,
    MatButtonModule
  ],
  providers: [LanguageService],
  exports: [
    //modules
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    
    HeaderComponent,
    FooterComponent,
    NewsCarouselComponent,
    BreadcrumbComponent,
    RequestNotificationComponent,
    SidemenuComponent,
    ControlsComponent
  ]
})
export class SharedModule { }
