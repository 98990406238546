import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PROVIDER } from 'src/environments/config';

@Component({
  selector: 'app-request-notification',
  templateUrl: './request-notification.component.html',
  styleUrls: ['./request-notification.component.scss']
})
export class RequestNotificationComponent {
  name = PROVIDER.providerName

  constructor(
    public dialogRef: MatDialogRef<RequestNotificationComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  yes() {
    this.dialogRef.close("yes");
  }

  no() {
    this.dialogRef.close("no");
  }
}
