<!-- News carousel -->
@if(blogproduct && blogproduct.length > 0) {
  <section class="py-5">
    <div class="container py-lg-3">
      <h2 class="h3 text-center">Noticias</h2>
      <p class="text-muted text-center mb-3 pb-4"></p>
      <ngx-slick-carousel [config]="blog">
        @for(blog of blogproduct;track $index){
        <div class="swiper-slide card product-card-alt" ngxSlickItem>
          <div style="margin: 0 15px;">
            <div class="card">
              <a class="blog-entry-thumb" href="javascript:void(0)" [routerLink]="['/noticia', removeDiacritics(blog.name), blog.id]">
                <img class="card-img-top" [src]=this.landingService.getPathImageNews(blog.image) alt="{{ blog.name }}" >
              </a>
              <div class="card-body">
                <h2 class="h6 blog-entry-title">
                  <a href="javascript:void(0)" [routerLink]="['/noticia', removeDiacritics(blog.name), blog.id]">{{ blog.name }}</a>
                </h2>
                <div class="fs-xs text-nowrap">
                  <a class="blog-entry-meta-link text-nowrap" href="javascript:void(0)" [routerLink]="['/noticia', removeDiacritics(blog.name), blog.id]">
                    {{ blog.createdAt }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </ngx-slick-carousel>
      <div class="text-center pt-4 mt-md-2">
        <a class="btn btn-outline-primary" routerLink="/noticia">
          Ver todos
          <i class="ci-arrow-right fs-ms ms-1"></i>
        </a>
      </div>
    </div>
  </section>
}
