import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cartdata } from 'src/app/pages/cart/data';

// Language
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/services/global/company.service';
import { LanguageService } from 'src/app/services/global/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isCollapsed = true;
  selectedLanguage: any;
  flag: any;
  countryName: any;
  cookieValue: any;
  flagvalue: any;
  valueset: any;

  obs: Subscription[] = []
	company: any = null

  constructor(private modalService: NgbModal,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private companyService : CompanyService,
  ) { 
    this.obs.push(
			this.companyService.currenCompany.subscribe(async (res: any) => {
				this.company = res
			})
		)
  }

  ngOnInit(): void {
    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.flagvalue = 'assets/img/flags/es.png'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.selectedLanguage = 'Es / $'
  }


  /***
 * Language Listing
 */
  listLang = [
    { text: 'Español', flag: 'assets/img/flags/es.png', lang: 'es', language: 'Es / $' },
    { text: 'English', flag: 'assets/img/flags/en.png', lang: 'en', language: 'Eng / $' }
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string,language:string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.selectedLanguage = language
    this.languageService.setLanguage(lang);
  }

  ngContentStart(): void {
    this.scrollToTop()
  }

  private scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  ngOnDestroy() {
		this.obs.forEach(element => {
		  element.unsubscribe()
		});
	}
}
