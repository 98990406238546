
<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title">{{'Permitir notificaciones'}}</h3>
  </div>
  <div class="modal-body">
    <p>¿Te gustaría recibir notificaciones de promociones {{name}}?</p>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-secondary btn-sm" (click)="no()">{{'No, gracias.'}}</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="yes()">{{'¡Sí deseo!'}}</button>  
  </div>
</div>
