<div class="mb-3 d-flex align-items-center">
    <select class="form-select me-3" style="width: 5rem;">
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
    </select>
    <button class="btn btn-primary btn-shadow d-block w-100" type="submit" (click)="addToCart(product)">
        <i class="ci-cart fs-lg me-2"></i>Ordenar
    </button>
</div>