import { Component } from '@angular/core';
import { blog } from 'src/app/pages/index/data';
import { LandingService } from 'src/app/services/landing/landing.service';
import { eliminarDiacriticos } from 'src/assets/utils/custom-code.strategy';

@Component({
  selector: 'app-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss']
})
export class NewsCarouselComponent {

  blogproduct: any[] = [];

  constructor(
    public landingService: LandingService,
  ) {

  }

  ngOnInit(): void {
    this.getNews()
  }

  blog = {
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
        },
      },
    ],
  };

  getNews(){
    this.blogproduct = []
    this.landingService.getNews().subscribe((res: any) => {
      this.blogproduct = res
    })
  }

  removeDiacritics(name: string) {
    let nameCode = eliminarDiacriticos(name)
    return nameCode;
  }
}
