import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROVIDER } from 'src/environments/config';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    domain = environment.domain;

    private currentCompanySubject: BehaviorSubject<any>;
    public currenCompany: Observable<any>;

    constructor(private http: HttpClient) {
        const storedCompany = sessionStorage.getItem('currentCompany');
        const parsedCompany = storedCompany ? JSON.parse(storedCompany) : {};  // Proporciona un objeto vacío como valor predeterminado
        this.currentCompanySubject = new BehaviorSubject<any>(parsedCompany);
        this.currenCompany = this.currentCompanySubject.asObservable();
    }

    public get currentCompanyValue(): any {
        return this.currentCompanySubject.value;
    }

    getConfigCompany() {
        let url_api = this.domain + 'api/em/company/get/' + (PROVIDER.providerId == null ? '' : PROVIDER.providerId);
        return this.http.get<any>(url_api)
            .pipe(map(res => {
                sessionStorage.setItem('currentCompany', JSON.stringify(res));
                this.currentCompanySubject.next(res);
                return res;
            }));
    }
}
