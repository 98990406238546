<div class="bg-secondary py-2">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                    @for(item of breadcrumbItems;track $index){
                    <ng-container>
                        <li class="breadcrumb-item text-nowrap" [ngClass]="{'active': item.active == true}"><a class="" routerLink="{{item.link}}">
                            @if(item.label == 'Home'){<i class="ci-home"></i> } {{ item.label }}</a>
                        </li>
                    </ng-container>
                    }
                </ol>
            </nav>
        </div>
        <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 class="h3 mb-0">{{title}}</h1>
        </div>
    </div>
</div>