import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CompanyService } from 'src/app/services/global/company.service';
import { NotificationService } from 'src/app/services/global/notificacion.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  constructor(
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private companyService: CompanyService
  ){
  }

  ngOnInit(): void {
    this.getCompany()
    this.getMessage()
  }

  getMessage(){
    if (this.authService.currentUserValue != null && this.authService.currentUserValue !== undefined) {
      //TODO this.authService.getUsetToken(this.authService.currentUserValue.token).subscribe(res => {
      //})
    } else {
      if (this.notificationService.permitiNotificacion.value == null) {
        this.notificationService.activeNotification(this.authService.currentUserValue);
      }
    }
  }

  getCompany() {
    this.companyService.getConfigCompany().subscribe(res => {
    })
  }
}
